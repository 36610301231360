import axios from "axios";
import { handleError } from "./error_handler/error_handler";

//export const baseUrl = "http://localhost:18782";
export const baseUrl = "";
const apiUrl = baseUrl + "/api/";



const get = (url) => {
    return axios
        .get(apiUrl + url)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            const message = handleError(err)
            throw new Error(message);
        });
}


const update = (url, newObject) => {
    return axios
        .patch(apiUrl + url, newObject)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            const message = handleError(err)
            throw new Error(message);
        });
}

const remove = (url) => {
    return axios
        .delete(apiUrl + url)
        .then((response) => {
            return;
        })
        .catch((err) => {
            const message = handleError(err)
            throw new Error(message);
        });
}


const create = (url, newObject) => {
    return axios
        .post(apiUrl + url, newObject)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            const message = handleError(err)
            throw new Error(message);
        });
}


const api = {
    get,
    update,
    create,
    remove
};

export default api;