import appBg from './assets/appBg.png';
import lovedUp from './assets/lovedUp.png';
import bouquet from './assets/bouquet.png';
import reload from './assets/reload.png';
import { useEffect, useState } from 'react';
import QrReader from "react-web-qr-reader";
import api from './api/api';

export default function Home() {
    const previewStyle = {
        width: '78%',
    };

    const [result, setResult] = useState();
    const [busy, setBusy] = useState(false);
    const [showResult, setShowResult] = useState(false);
    useEffect(() => {
        if (result && !showResult) {
            setShowResult(true);
        }
    }, [result])

    const [status, setStatus] = useState(null);

    const statusMessageHandler = (message, success) => {
        setStatus({ success, message: message?.toString() });
        setTimeout(() => {
            setStatus(null)
        }, 5000);
    }

    const handleScan = (result) => {
        if (result && result.data) {
            const inviteeId = result.data.split('--')[1]
            setBusy(true);
            api.create('attendees', { inviteeId })
                .then((data) => {
                    console.log(data)
                    setResult(data)
                    setBusy(false);
                })
                .catch(err => {
                    setBusy(false);
                    console.log(err)
                    statusMessageHandler(err.message);
                })
        }
    };

    const handleError = (error) => {
        console.log(error);
        statusMessageHandler(error);
    };

    const showQr = () => {
        setShowResult(false);
        setResult(null);
    }
    return (
        <AppBg>
            {busy && <Loader />}
            <Toast message={status?.message} className={status && 'show'} />
            <div className='app-content p-relative'>
                <div className='app-content-children'>
                    {
                        showResult ?
                            <Result data={result} onClick={showQr} />
                            : <QrReader
                                className='custom-qr'
                                style={previewStyle}
                                onError={handleError}
                                onScan={handleScan} />
                    }


                </div>
                {/*<div className='p-fixed img-cont z-index-3'>*/}
                {/*    <img src={bouquet} className='bouquet z-index-3' alt='bouquet decoration' />*/}
                {/*</div>*/}
            </div>
        </AppBg>
    );
}
export const Loader = () => {
    return (
        <div className='loader'>
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

const Result = ({ data, onClick }) => {
    const { invitee } = data || {}

    return (
        <div className='app-border'>
            <h6>Name</h6>
            <p className='m3'>{invitee?.name}</p>
            <h6>Table Number</h6>
            <p className='m3'>Table {invitee?.tableNo}</p>

            {
                invitee?.otherGuests && (
                    <>
                        <h6>Other Guests</h6>
                        <p>{invitee?.otherGuests}</p>
                    </>
                     
                )
            }

            <button onClick={onClick} className='unstyle-button app-button' type='button'><img src={reload} alt='reload' /> Scan another guest</button>
        </div>
    )
}


const AppBg = ({ children }) => {
    return (
        <div className='p-relative app-bg'>
            {/*<img src={lovedUp} className='p-fixed main-image' alt='Chisom and Chibuzo' />*/}
            {/*<img src={appBg} className='p-fixed other-image' alt='page back drop' />*/}
            {children}
        </div>
    );
}


export const Toast = ({ message, className }) => {
    return message ? (
        <div id="toast" className={className || ''}>
            <div id="img">Error</div>
            <div id="desc">{message}</div>
        </div>
    ) : <></>;
}